body {
    margin-top: 10px;
    font-size: 14px;
    font-family: "Lucida Grande",Helvetica,Arial,Verdana,sans-serif;
}

.flip_card_container {
    border-radius: 5px;
    padding: 15px;
    height: 800px;
    vertical-align: top;
    overflow: scroll;
}
.time_zone_select {
    margin-bottom: 1px;
    width:200px;
}
.org_select {
    margin-bottom: 1px;
    width:100px;
}
.show_button {
    width:30px;
    height:30px;
    margin-left: 10px;
}

.flip_card {
    border-radius: 5px;
    padding: 5px;
    vertical-align: top;
}
.calendar {
    border-radius: 5px;
    vertical-align: top;
    margin: 5px;
    padding: 5px;
}
.table {
    border-radius: 5px;
    margin-top: 10px;
   
}
.schedule {
    border-radius: 5px;
    margin-top: 40px;
    vertical-align: top;
}

.timezone{
    margin-top: 10px;
}
.timezone_value{
    margin-top: 5px;
    margin-left: 10px;
}
.timezone_label{
  font-size: 2;
}
.org_control{
    margin-top: 40px;
    width:200px
}
.org_select{
    margin-left: 10px;
    width:200px
}
.organization_list{
    box-shadow: 0 3px 5px 2px #b9a4af4d;
    border-radius: 5px;
    margin-left: 0px;
    padding-left: 10px;
    width:15%;
    vertical-align: top;
    padding-top: 20px;
    text-align: left;
}

.provider_list_title {
    margin-left: -10px;
}
.provider_list {
    width: 210px;
    margin-top: 10px;
    padding:0px;
    border: 0px solid rgba(226, 225, 225, 0.705);
    border-radius: 2px;
    background: rgba(215, 238, 247, 0.644);
    text-align: left;
    vertical-align: top;
}

.provider {
    padding:0px;
    margin-top: -10px;
    margin-bottom: -10px;
    cursor: pointer;
    vertical-align: top;
    color: #281653;
    text-align: left;
}

.provider_details_dialog {
    vertical-align: top;
    text-align: left;
}
.phone_icon {
    width:  15px;
    height: 15px;
    color: #e4041a;
}
.phone_icon_red {
    width:15px;
    height:15px;
}
.mail_icon {
    width:15px;
}

.messagesPanel {
    margin-top: '20px';
    width: 800px;
    height: 100%;
    justify-content: 'top';
    
  }

.messages {
    margin-top: '20px';
    width: 100%;
    justify-content: 'top';
  }