@import url("https://fonts.googleapis.com/css?family=Roboto:300,400");

table.styled_table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  width: 100%;
  border: 0.5px solid #ede9d0;
}

table.styled_table td,
table.styled_table th {
  border: 0.5px solid #b8ac86;
  padding: 8px;
}
table.styled_table tr td {
  padding-bottom: 0px;
}
table.styled_table tr th {
  padding: 12px;
  text-align: center;
  font-weight: 500;
  background-color: #ede9d0;
  color: #374140;
}

table.styled_table tr:nth-child(odd) {
  background-color: #ffffff;
}

.question_section_heading {
  font-weight: 600 !important;
}

.no-padding-list {
  padding-left: 1.2em;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.no-bullet-list {
  list-style-type: none;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  display: flex;
  flex-direction: "column";
  justify-content: "start";
}

table.simple_table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  border: 0.5px solid #ede9d0;
  margin-bottom: 8px;
}

table.simple_table td,
table.simple_table th {
  border: 0.5px solid #d4cdb5;
  padding: 8px;
}
table.simple_table tr td {
  padding-bottom: 0px;
}
table.simple_table tr th {
  text-align: left;
  font-weight: 500;
  background-color: #ede9d0;
  color: #374140;
}

table.simple_table tr:nth-child(odd) {
  background-color: #ffffff;
}

table.simple_table tr:hover {
  background-color: #ffffffda;
}

table.empty_table {
  padding: 0px;
  border: none;
  border-collapse: collapse;
}
table.empty_table tr td {
  border: none;
}
