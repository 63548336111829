form {
    margin-right: 10px !important;
    width: max-content;
}

div.drawer > div {
    margin: 7px;
    width: 100% !important;
}

.appbar, .header {
    background-color: #ff9800 !important;
}
.edit_org {
    max-width: 1500px;
    width: 1500px;
}
.error {
    color: #ec3a3a;
}

.drawer {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px;
    max-width: 70vw;
    text-align: start;
}
.contact_table {
    background-color: #3f51b5 !important;
    color: #ffffff;
    display: inline;
    margin: 7px;
}
.filter {
    margin: 5px;
    width: 33%;
}
.notes {
    background: #f6ffef;
    font-size: 12px;
}
.notes_warning {
    color: #ec3a3a;
    font-size: 12px;
}
.time_picker {
    font-size: 12px;
    margin-left: 10px;
    width: 100px;
}