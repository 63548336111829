

.log-Error {
  color: #ff0000;
}
.log-Debug {
  color: #1a1919;
}
.log-Critical {
  color: #ff6600;
}
.log-Info {
  color: #1100ff;
}
.log-Warning {
  color: #ff00ea;
}
