@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
    margin-top: 10px;
    font-size: 14px;
    font-family: "Lucida Grande",Helvetica,Arial,Verdana,sans-serif;
}

.flip_card_container {
    border-radius: 5px;
    padding: 15px;
    height: 800px;
    vertical-align: top;
    overflow: scroll;
}
.time_zone_select {
    margin-bottom: 1px;
    width:200px;
}
.org_select {
    margin-bottom: 1px;
    width:100px;
}
.show_button {
    width:30px;
    height:30px;
    margin-left: 10px;
}

.flip_card {
    border-radius: 5px;
    padding: 5px;
    vertical-align: top;
}
.calendar {
    border-radius: 5px;
    vertical-align: top;
    margin: 5px;
    padding: 5px;
}
.table {
    border-radius: 5px;
    margin-top: 10px;
   
}
.schedule {
    border-radius: 5px;
    margin-top: 40px;
    vertical-align: top;
}

.timezone{
    margin-top: 10px;
}
.timezone_value{
    margin-top: 5px;
    margin-left: 10px;
}
.timezone_label{
  font-size: 2;
}
.org_control{
    margin-top: 40px;
    width:200px
}
.org_select{
    margin-left: 10px;
    width:200px
}
.organization_list{
    box-shadow: 0 3px 5px 2px #b9a4af4d;
    border-radius: 5px;
    margin-left: 0px;
    padding-left: 10px;
    width:15%;
    vertical-align: top;
    padding-top: 20px;
    text-align: left;
}

.provider_list_title {
    margin-left: -10px;
}
.provider_list {
    width: 210px;
    margin-top: 10px;
    padding:0px;
    border: 0px solid rgba(226, 225, 225, 0.705);
    border-radius: 2px;
    background: rgba(215, 238, 247, 0.644);
    text-align: left;
    vertical-align: top;
}

.provider {
    padding:0px;
    margin-top: -10px;
    margin-bottom: -10px;
    cursor: pointer;
    vertical-align: top;
    color: #281653;
    text-align: left;
}

.provider_details_dialog {
    vertical-align: top;
    text-align: left;
}
.phone_icon {
    width:  15px;
    height: 15px;
    color: #e4041a;
}
.phone_icon_red {
    width:15px;
    height:15px;
}
.mail_icon {
    width:15px;
}

.messagesPanel {
    margin-top: '20px';
    width: 800px;
    height: 100%;
    justify-content: 'top';
    
  }

.messages {
    margin-top: '20px';
    width: 100%;
    justify-content: 'top';
  }

table.styled_table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  width: 100%;
  border: 0.5px solid #ede9d0;
}

table.styled_table td,
table.styled_table th {
  border: 0.5px solid #b8ac86;
  padding: 8px;
}
table.styled_table tr td {
  padding-bottom: 0px;
}
table.styled_table tr th {
  padding: 12px;
  text-align: center;
  font-weight: 500;
  background-color: #ede9d0;
  color: #374140;
}

table.styled_table tr:nth-child(odd) {
  background-color: #ffffff;
}

.question_section_heading {
  font-weight: 600 !important;
}

.no-padding-list {
  padding-left: 1.2em;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.no-bullet-list {
  list-style-type: none;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  display: flex;
  flex-direction: "column";
  justify-content: "start";
}

table.simple_table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  border: 0.5px solid #ede9d0;
  margin-bottom: 8px;
}

table.simple_table td,
table.simple_table th {
  border: 0.5px solid #d4cdb5;
  padding: 8px;
}
table.simple_table tr td {
  padding-bottom: 0px;
}
table.simple_table tr th {
  text-align: left;
  font-weight: 500;
  background-color: #ede9d0;
  color: #374140;
}

table.simple_table tr:nth-child(odd) {
  background-color: #ffffff;
}

table.simple_table tr:hover {
  background-color: #ffffffda;
}

table.empty_table {
  padding: 0px;
  border: none;
  border-collapse: collapse;
}
table.empty_table tr td {
  border: none;
}

/* Fonts */

.edit_rule_icon {
  width: "2px";
  height: 2px;
  padding: 0px;
  margin: 0px;
}

.edit_rule_button {
  padding: 0px;
  margin: 0px;
}


.table {
  width: 100%;
  margin-top: 20px;
  background-color: #d1ccce;
  font-size: 10px;
}
.th {
  font-size: 10px;
}



.title {
  background-color: #ffffff;
  padding: 15px;
  text-align: center;
}

.question_id {
  margin-top: 20px;
  width: 100%;
  text-align: left;
  border: 0px solid rgb(30, 60, 194);
  font-size: 14px;
  font-family: Calibri;
  border-collapse: collapse;
}
.question {
  width: 100%;
  margin-left: 10px;
  text-align: left;
  margin-bottom: 0px;
  border: 0px solid rgb(30, 60, 194);
  font-size: 14px;
  font-family: Calibri;
  border-collapse: collapse;
}
.answer_list {
  align-content: top;
  margin-left: 20px;
  margin-top: 0px;
  padding-left: 20px;
  background-color: #fafdfd;
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.trigger_table {
  width: 100%;
  margin-top: 20px;
  background-color: #ececec;
  border: 1px solid rgb(182, 186, 204);
  font-size: 10px;
  border-collapse: collapse;
}

.detect_table {
  width: 100%;
  margin-top: 20px;
  background-color: #ececec;
  border: 0.5px solid rgb(182, 186, 204);
  font-size: 10px;
  border-collapse: collapse;
}
.symptom_row {
  width: 100%;
  border: 1px solid rgb(196, 204, 241);
  border-collapse: collapse;
}
.symptom_title {
  margin-top: 0px;
  padding: 5px;
  background-color: #c0e1f7;
  border: 1px solid rgb(210, 211, 218);
  text-align: center;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.trigger_symptom {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.trigger_symptom_model {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.trigger_symptom_detail {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_trigger_symptom {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_trigger_symptom_detail {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_trigger_symptom_detail_center {
  align-content: top;
  padding: 5px;
  background-color: #f8f6f6;
  border: 1px solid rgb(196, 197, 202);
  text-align: center;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.detect_seperator {
  background-color: #ffffff;
  width: 100%;
  text-align: bottom;
  border: 1px solid rgb(255, 255, 255);
  font-size: 24px;
  padding-bottom: 5px;
  padding-top: 50px;
}
.detect_symptom_title {
  margin-top: 0px;
  padding: 5px;
  background-color: #d7d8d8;
  border: 1px solid rgb(210, 211, 218);
  text-align: center;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}
.detect_symptom {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.symptom_detail {
  align-content: top;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.symptom_detail_center {
  align-content: center;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid rgb(196, 197, 202);
  text-align: center;
  font-family: Calibri;
  vertical-align: center;
  font-size: 14px;
  justify-content: center;
}

.question_id_list {
  align-content: top;
  margin-left: 10px;
  margin-bottom: 2px;
  margin-top: 2px;
  margin-right: 2px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.question_id_list_item {
  align-content: top;
  margin-left: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  font-family: Calibri;
  vertical-align: top;
  font-size: 14px;
  justify-content: top;
}

.detect_rule_model_table {
  width: 100%;
  /* margin: 10px; */
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border: 0.5px solid #b8ac86;
}

.detect_rule_model_table tr th {
  border: 0.5px solid #b8ac86;
}

.detect_rule_model_table tr td {
  border: 0.5px solid #b8ac86;
}

.break {
  background: #3e87bc;
  height: 4px;
  margin: 5px 0 10px 0;
  width: 100%;
}

.detect_rule_model_header {
  background-color: #EDE9D0;
  font-weight: 600 ;
  color: #374140 ;
  padding: 8px;
}

.detect_rule_model_seperator {
  padding: 8px;
  padding-top: 20px;
}
.detect_rule_model_grade {
  background-color: #c8dbf8;
  color: #003f0f;
  padding: 8px;
  min-width: '10rem';
}
.detect_rule_model_alert {
  background-color: #effffd;
  color: #003f0f;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_alert_green {
  background-color: #098b34;
  color: #ffffff;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_alert_yellow {
  background-color: #f8f82c;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_alert_orange {
  background-color: #ff6600;
  color: #ffffff;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_alert_red {
  background-color: #e4041a;
  color: #ffffff;
  padding: 8px;
  text-align: center;
}

.detect_rule_model_priority {
  background-color: #d6cfc2;
  color: #003f0f;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_primary {
  background-color: #ffffff;
  color: #e4041a;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_associated {
  background-color: #ffffff;
  color: #0059ff;
  padding: 8px;
  text-align: center;
}
.detect_rule_model_clinical {
  background-color: #ffffff;
  color: #007710;
  padding: 8px;
  text-align: center;
}

.validation_report_table {
  background-color: #ffffff;
}

.validation_report_model_seperator {
  background-color: #ffffff;
  color: #220072;
  border: 1px solid rgb(255, 255, 255);
  padding: 8px;
}
.validation_report_model_header {
  background-color: #ffffff;
  color: #220072;
  border: 1px solid rgb(255, 255, 255);
  padding: 8px;
  text-align: center;
}

.validation_report_symptom_heading {
  background-color: #07a507;
  color: #ffffff;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: center;
}

.validation_report_heading {
  background-color: #dbdbdb;
  color: #220072;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: center;
}
.validation_report_symptom {
  background-color: #ffffff;
  width: 20%;
  color: #220072;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}

.validation_report_symptom_row {
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}
.validation_report_no_answer {
  background-color: #f0aeae;
  color: #ee0000;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}

.validation_report_answer {
  background-color: #ffffff;
  color: #220072;
  border: 1px solid rgb(40, 42, 51);
  padding: 8px;
  text-align: left;
}

.recommendation_groups_table {
  width: 100%;
  margin-top: 20px;
  background-color: #ececec;
  border: 1px solid rgb(182, 186, 204);
  font-size: 10px;
  border-collapse: collapse;
}



.log-Error {
  color: #ff0000;
}
.log-Debug {
  color: #1a1919;
}
.log-Critical {
  color: #ff6600;
}
.log-Info {
  color: #1100ff;
}
.log-Warning {
  color: #ff00ea;
}

form {
    margin-right: 10px !important;
    width: -webkit-max-content;
    width: max-content;
}

div.drawer > div {
    margin: 7px;
    width: 100% !important;
}

.appbar, .header {
    background-color: #ff9800 !important;
}
.edit_org {
    max-width: 1500px;
    width: 1500px;
}
.error {
    color: #ec3a3a;
}

.drawer {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px;
    max-width: 70vw;
    text-align: start;
}
.contact_table {
    background-color: #3f51b5 !important;
    color: #ffffff;
    display: inline;
    margin: 7px;
}
.filter {
    margin: 5px;
    width: 33%;
}
.notes {
    background: #f6ffef;
    font-size: 12px;
}
.notes_warning {
    color: #ec3a3a;
    font-size: 12px;
}
.time_picker {
    font-size: 12px;
    margin-left: 10px;
    width: 100px;
}
table.styled_table {
  border: 0px solid #ddd;
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
}

table.styled_table td,
table.styled_table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table.styled_table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.styled_table tr:hover {
  background-color: #ddd;
}

table.styled_table th {
  background-color: #05529b;
  color: white;
  padding-bottom: 12px;
  padding-top: 12px;
  text-align: left;
}

table.styled_table th.title_case {
  text-transform: capitalize;
}
.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 250px;
  margin: 2em;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


