@import url("https://fonts.googleapis.com/css?family=Roboto:300,400");

table.styled_table {
  border: 0px solid #ddd;
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
}

table.styled_table td,
table.styled_table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table.styled_table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.styled_table tr:hover {
  background-color: #ddd;
}

table.styled_table th {
  background-color: #05529b;
  color: white;
  padding-bottom: 12px;
  padding-top: 12px;
  text-align: left;
}

table.styled_table th.title_case {
  text-transform: capitalize;
}